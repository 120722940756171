
<div class="container-fluid p-32 form-container"  [formGroup]="formGp" autocomplete="off">
  <div class="row ">
      <p class="text-uppercase fw-500">LATE COMING PENALTY</p>
  </div>
  <div class="row row-16">
    <div class="col-12 form-row">
      <label for="enavle penality" class="form-label required">Do you want to enable penalty if the allowed number of late in or early out incidents exceeded? </label>
    </div>
    <div class="col-lg-12 form-row mt-0" >
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input formControlName="late_coming_penalty" class="form-check-input" type="radio"  [value]="true"  [attr.disabled]="this.detailsForm.controls.keep_time.value!='Strict'? true : null" (click)="setRequired(true,lateData);">
                Yes
            </label>
        </div>
        <div class="form-check form-check-inline">
            <label class="form-check-label" >
                <input formControlName="late_coming_penalty" class="form-check-input" type="radio" [value]="false" (click)="setRequired(false,lateNoData);">
                No
            </label>
        </div>
    </div>
     <ng-container *ngIf="f.late_coming_penalty.value == true">
      <div class="col-12">
        <label for="" class="form-label required"> Which incident do you want to penalized? </label>
      </div>
      <div class="col-12 d-flex gap-16 mt-0"  formArrayName="incident_penalize">
        <label for="inci_late" class="form-check p-0 d-flex ">
          <input type="checkbox" name="incident_penalize" id="inci_late" [value]="'Late in'" class="form-check-input ms-0" (click)="onCheckboxChange($event,'incident_penalize')" [checked]="isChecked('Late in','incident_penalize')">
          <label for="inci_late" class="form-check-label fs-16">Late in</label>
        </label>
        <label for="inci_early" class="form-check p-0 d-flex ">
          <input type="checkbox" name="incident_penalize" id="inci_early" [value]="'Early out'" class="form-check-input ms-0" (click)="onCheckboxChange($event,'incident_penalize')" [checked]="isChecked('Early out','incident_penalize')">
          <label for="inci_early" class="form-check-label fs-16">Early out</label>
        </label>
      </div>
      <div class="col-12">
        <label for="" class="form-label required">Grace days allowed in a </label>
      </div>
      <div class="col-12 mt-8 form-row">
        <div class="form-check form-check-inline">
          <input name="grace_days_cond" type="radio" id="grace_cond1" class="form-check-input" formControlName="grace_days_cond" [value]="true" (change)="setValidationGrace()">
          <label for="grace_cond1" class="form-check-label">Month</label>
        </div>
        <div class="form-check form-check-inline">
          <input name="grace_days_cond" type="radio" id="grace_cond2" class="form-check-input" formControlName="grace_days_cond" [value]="false" (change)="setValidationGrace()">
          <label for="grace_cond2" class="form-check-label">Week</label>
        </div>
      </div>
      <div class="col-12">
              <input formControlName="grace_days_count" [ngClass]="{ 'is-invalid': (submitted || f.grace_days_count.touched|| f.grace_days_count.dirty) &&  f.grace_days_count.errors}" type="text" class="form-control " placeholder="{{this.messageService.placeholderdisp('days')}}">
        <div *ngIf="(submitted || f.grace_days_count.touched|| f.grace_days_count.dirty) && f.grace_days_count.errors" class="invalid-feedback">
            <div *ngIf="f.grace_days_count.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            <div *ngIf="!f.grace_days_count.errors.pattern && f.grace_days_count?.hasError('min')">{{this.messageService.fieldlengthvalidation('minvalue',0)}}
            </div>
            <div *ngIf="!f.grace_days_count.errors.pattern && f.grace_days_count?.hasError('max')">{{this.messageService.fieldlengthvalidation('value',maxGraceLimit)}}
            </div>
            <div *ngIf="f.grace_days_count.errors.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
        </div>
      </div>
      <div class="col-12">
        <label for="" class="form-label required">How do you want to apply penalty if the the grace days limit is
          exceeded?</label>
      </div>
      <div class="col-12 mt-8 d-flex align-items-baseline gap-8 flex-wrap">
        <span class="">For every </span>
        <div>
          <input type="text" pattern="^[0-9]+$" autocomplete="off" name="exceed_limit" class="form-control" [ngClass]="{ 'is-invalid': (submitted || f.exceeded_limit.touched|| f.exceeded_limit.dirty) &&  f.exceeded_limit.errors}" style="width:18rem" placeholder="{{this.messageService.placeholderdisp('number of times')}}" formControlName="exceeded_limit" id="exceed_limit">
          <div *ngIf="(submitted || f.exceeded_limit.touched|| f.exceeded_limit.dirty) && f.exceeded_limit.errors" class="invalid-feedback">
            <div *ngIf="f.exceeded_limit.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            <div *ngIf="!f.exceeded_limit.errors?.pattern && f.exceeded_limit.hasError('max')">
              {{this.messageService.fieldlengthvalidation('days','365')}}
            </div>
            <div
              *ngIf="!f.exceeded_limit.errors?.pattern && f.exceeded_limit.hasError('min')">
              {{this.messageService.fieldlengthvalidation('minvalue','0')}}
            </div>
            <div
                *ngIf="f.exceeded_limit.errors?.pattern || notanumber(f.exceeded_limit.errors.pattern?.actualValue)">
                {{this.messageService.validationDisplay('pattern')}}
            </div>
          </div>
        </div>
        <span class="">times, deduct </span>
        <div>
                <input type="text" pattern="-?\d+(\.\d+)?$" autocomplete="off" [ngClass]="{ 'is-invalid': (submitted || f.exceeded_deduct_days.touched|| f.exceeded_deduct_days.dirty) &&  f.exceeded_deduct_days.errors}" name="excedd_deduct" class="form-control" style="width:18rem" formControlName="exceeded_deduct_days" placeholder="{{this.messageService.placeholderdisp('number of days')}}" id="excedd_deduct">
          <div *ngIf="(submitted || f.exceeded_deduct_days.touched|| f.exceeded_deduct_days.dirty) && f.exceeded_deduct_days.errors" class="invalid-feedback">
            <div *ngIf="f.exceeded_deduct_days.errors.required">{{this.messageService.validationDisplay('required')}}</div>
            <div *ngIf="!f.exceeded_deduct_days.errors?.pattern && f.exceeded_deduct_days.hasError('max')">
              {{this.messageService.fieldlengthvalidation('days','365')}}
            </div>
            <div
              *ngIf="!f.exceeded_deduct_days.errors?.pattern && f.exceeded_deduct_days.hasError('min')">
                    {{this.messageService.fieldlengthvalidation('minvalue',0.5)}}
            </div>
            <div
                *ngIf="f.exceeded_deduct_days.errors?.pattern || notanumber(f.exceeded_deduct_days.errors.pattern?.actualValue)">
                {{this.messageService.validationDisplay('pattern')}}
            </div>
                  <div *ngIf="(!f.exceeded_deduct_days.errors?.pattern && !f.exceeded_deduct_days?.errors?.required && f.exceeded_deduct_days.errors?.invalidDayDuration)">
                    {{this.messageService.validationDisplay('pattern')}}
                  </div>
                </div>
              </div>
        <span class="excedd_deduct_days">days </span>
      </div>
      <div class="col-12">
        <label for="" class="form-label required">Do you want to ignore above penalization, if employee is already penalized for not completing minimum working hours for
        the day?</label>
      </div>
      <div class="col-12 mt-8 form-row">
        <div class="form-check form-check-inline">
          <input name="ignore_penalize_cond" type="radio" id="ignore_penalize_yes" class="form-check-input" formControlName="ignore_penalize_cond" [value]="true" (change)="setRequired(true,['ignore_penalize_option'])">
          <label for="ignore_penalize_yes" class="form-check-label">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input name="ignore_penalize_cond" type="radio" id="ignore_penalize_no" class="form-check-input" formControlName="ignore_penalize_cond" [value]="false"(change)="setRequired(false,['ignore_penalize_option'])">
          <label for="ignore_penalize_no" class="form-check-label">No</label>
        </div>
      </div>
      <ng-container *ngIf="f.ignore_penalize_cond.value == true">
        <div class="col-12 mt-8">
          <ng-select
          [readonly]="disabled" [ngClass]="{ 'is-invalid': (f.ignore_penalize_option.touched) &&  f.ignore_penalize_option.errors}"  placeholder="{{this.messageService.selectplaceholddisplay('deductions')}}" formControlName="ignore_penalize_option" [items]="deductionTypes" [multiple]="true" groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false" bindLabel="value" [closeOnSelect]="false" bindValue="id" class="multiselect">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{standalone: true}"/> Select All
            </ng-template>
            <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index">
                <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }" />
                {{ item.value }}
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngIf="formGp.value.ignore_penalize_option?.length">
                    <span class="ng-value-label">{{formGp.value.ignore_penalize_option?.length}} deduction selected.</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                {{ (item.value?.length>15)? (item.value | slice:0:15)+'..':(item.value)  }}
            </ng-template>
          </ng-select>
          <div *ngIf="(submitted || f.ignore_penalize_option.touched) && f.ignore_penalize_option.errors" class="invalid-feedback">
            <div *ngIf="f.ignore_penalize_option.errors.required">{{this.messageService.validationDisplay('required')}}
            </div>
          </div>
        </div>
        <div class="col-12">
          <ul class="selected-values">
              <li class="ng-value" *ngFor="let item of formGp.value.ignore_penalize_option, let m =index">
                  <ng-container *ngFor="let insideitem of deductionTypes, let k =index">
                  <span class="ng-value-label" *ngIf="insideitem['id']==item">
                      {{ (insideitem['value']?.length>15)? (insideitem['value'] | slice:0:15)+'..':(insideitem['value'])  }}</span>
                  </ng-container>
                  <span *ngIf="disabled == false" class="ng-value-icon right" aria-hidden="true" (click)="clear(m,'ignore_penalize_option')"><i class="icon-close"></i></span>
            </li>
          </ul>
        </div>
      </ng-container>
    </ng-container>
    <div class="col-12">
      <label for="" class="form-label required">Do you want to enable a penalty if the cumulative hours for late in or early out allowed are exceeded?  </label>
    </div>
    <div class="col-12 mt-8 form-row">
      <div class="form-check form-check-inline">
        <input name="penalty_cumulative_cond" formControlName="penalty_cumulative_cond" type="radio" id="penality_cond_yes" class="form-check-input" [value]="true" (change)="setRequired(true,deducData);addDeduction()">
        <label for="penality_cond_yes" class="form-check-label">Yes </label>
      </div>
      <div class="form-check form-check-inline">
        <input name="penalty_cumulative_cond" formControlName="penalty_cumulative_cond" type="radio" id="penality_cond_no" class="form-check-input" [value]="false" (change)="setRequired(false,deducNoData);clearDeduction();">
        <label for="penality_cond_no" class="form-check-label">No </label>
      </div>
    </div>
    <ng-container *ngIf="f.penalty_cumulative_cond.value == true">
      <div class="col-12">
        <label for="incident_penalise" class="form-label required"> Which incident do you want to penalized? </label>
      </div>
      <div class="col-12 d-flex gap-16 mt-0" formArrayName="incident_cumulative">
        <label for="check" class="form-check p-0 d-flex">
          <input type="checkbox" name="incident_cumulative" id="incident_cumulative_yes" class="form-check-input ms-0" (change)="onCheckboxChange($event,'incident_cumulative')" [value]="'Late in'" [checked]="isChecked('Late in','incident_cumulative')">
          <label for="incident_cumulative_yes" class="form-check-label fs-16">Late in</label>
        </label>
        <label for="check" class="form-check p-0 d-flex ">
          <input type="checkbox" name="incident_cumulative" id="incident_cumulative_no" class="form-check-input ms-0" (change)="onCheckboxChange($event,'incident_cumulative')" [value]="'Early out'" [checked]="isChecked('Early out','incident_cumulative')">
          <label for="incident_cumulative_no" class="form-check-label fs-16">Early out</label>
        </label>
      </div>
      <div class="col-12">
        <label for="" class="form-label required">Penalization is based on  </label>
        <ng-select class="form-ngselect" placeholder="{{this.messageService.selectplaceholddisplay('options')}}" formControlName="cumulative_type">
          <ng-option *ngFor="let drop of penalisation_dropdown" [value]="drop">{{drop}}</ng-option>
        </ng-select>
      </div>
      <div class="col-12">
        <label for="criteriaa" class="form-label required">Specify deduction criteria below: </label>
      </div>
      <ng-container formArrayName="cumulative_criteria">
        <ng-container *ngFor="let data of deduction_prio().controls; let i=index;" [formGroupName]="i">
          <div class="align-items-start d-flex flex-nowrap row row-16 {{i>0 ? 'mt-10':''}}">
            <div class="col-12 mt-8 d-flex align-items-baseline gap-8 flex-wrap">
              <span class="">If the cumulative hours is</span>
              <div>
                <input type="text" pattern="^-?\d+$" autocomplete="off" [ngClass]="{'is-invalid': (submitted || deduction_prio().controls[i].get('cumulative_hours')?.touched|| deduction_prio().controls[i].get('cumulative_hours')?.dirty) && deduction_prio().controls[i].get('cumulative_hours')?.errors}"  name="cumulative_hours" formControlName="cumulative_hours" class="form-control" style="width:18rem" placeholder="{{this.messageService.placeholderdisp('hours')}}" id="hourss" (keyup)="onHoursKeyUp(i)">
                <div *ngIf="(submitted || deduction_prio().controls[i].get('cumulative_hours')?.touched|| deduction_prio().controls[i].get('cumulative_hours')?.dirty) && deduction_prio().controls[i].get('cumulative_hours')?.errors" class="invalid-feedback">
                  <div *ngIf="deduction_prio().controls[i].get('cumulative_hours')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="!deduction_prio().controls[i].get('cumulative_hours')?.errors?.pattern && deduction_prio().controls[i].get('cumulative_hours')?.hasError('max')">
                    {{this.messageService.fieldlengthvalidation('hours','24')}}
                  </div>
                  <div
                    *ngIf="!deduction_prio().controls[i].get('cumulative_hours')?.errors?.pattern && deduction_prio().controls[i].get('cumulative_hours')?.hasError('min')">
                    {{this.messageService.fieldlengthvalidation('minvalue','0')}}
                  </div>
                  <div
                  *ngIf="!deduction_prio().controls[i].get('cumulative_hours')?.errors?.pattern && deduction_prio().controls[i].get('cumulative_hours')?.hasError('duplicate')">
                  Duplicate entries found on cumilative hours.
                </div>
                  <div
                      *ngIf="deduction_prio().controls[i].get('cumulative_hours')?.errors?.pattern || notanumber(deduction_prio().controls[i].get('cumulative_hours')?.errors?.pattern?.actualValue)">
                      {{this.messageService.validationDisplay('pattern')}}
                  </div>
                </div>
              </div>
              <span class=""> deduct </span>
              <div>
                      <input type="text" pattern="-?\d+(\.\d+)?$" autocomplete="off" [ngClass]="{'is-invalid' : (submitted || deduction_prio().controls[i].get('count_days')?.touched|| deduction_prio().controls[i].get('count_days')?.dirty) && deduction_prio().controls[i].get('count_days')?.errors}"  name="count_days" formControlName="count_days" class="form-control" style="width:18rem" placeholder="{{this.messageService.placeholderdisp('days')}}" id="dayss">
                <div *ngIf="(submitted || deduction_prio().controls[i].get('count_days')?.touched|| deduction_prio().controls[i].get('count_days')?.dirty) && deduction_prio().controls[i].get('count_days')?.errors" class="invalid-feedback">
                  <div *ngIf="deduction_prio().controls[i].get('count_days')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                  <div *ngIf="!deduction_prio().controls[i].get('count_days')?.errors?.pattern && deduction_prio().controls[i].get('count_days')?.hasError('max')">
                    {{this.messageService.fieldlengthvalidation('days','365')}}
                  </div>
                  <div
                    *ngIf="!deduction_prio().controls[i].get('count_days')?.errors?.pattern && deduction_prio().controls[i].get('count_days')?.hasError('min')">
                          {{this.messageService.fieldlengthvalidation('minvalue',0.5)}}
                  </div>
                  <div
                      *ngIf="deduction_prio().controls[i].get('count_days')?.errors?.pattern || notanumber(deduction_prio().controls[i].get('count_days')?.errors?.pattern?.actualValue)">
                      {{this.messageService.validationDisplay('pattern')}}
                  </div>
                        <div
                            *ngIf="(!deduction_prio().controls[i].get('count_days')?.errors?.pattern && !deduction_prio().controls[i].get('count_days')?.errors?.required && deduction_prio().controls[i].get('count_days')?.errors?.invalidDayDuration)">
                            {{this.messageService.validationDisplay('pattern')}}
                        </div>
                      </div>
                    </div>
              <span class="">days </span>
            </div>
            <a class="flex-center sq-40 text-danger mt-0" (click)="deleteDeduction(i)" *ngIf="i!=0">
              <i class="icon-trash "></i>
          </a>
          </div>
        </ng-container>
      </ng-container>
      <div class="col-12">
        <div class="link-primary1 fs-14 d-inline-flex align-items-center" (click)="addDeduction();">
          <i class="icon-plus-square-fill fs-16 me-8"></i>Add criteria
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="f.late_coming_penalty.value ==  true || f.penalty_cumulative_cond.value == true">
      <div class="col-12 form-row">
        <label for="done" class="form-label required">Penalization has to be done from</label>
      </div>
      <div class="col-lg-12 form-row mt-0">
          <div class="form-check form-check-inline">
              <label class="form-check-label" >
                  <input formControlName="late_come_go_early_deduction" class="form-check-input" type="radio"  [value]=false (click)="setDisabled(false)">
                  Attendance
              </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="In case of Attendance, late coming deduction is done from paid days ie. Paid Days of the month = Paid days as per calendar - Late coming Deduction" popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
          </div>
          <div class="form-check form-check-inline">
              <label class="form-check-label" >
                  <input formControlName="late_come_go_early_deduction" class="form-check-input" type="radio" [value]=true (click)="setDisabled(true)">
                  Leave
              </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon" ngbPopover="In case of Leave, late coming deduction is done from leave balance. You have to define the sequence of leave types from which balance will be deducted" popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
          </div>
      </div>
      <ng-container *ngIf="f.late_come_go_early_deduction.value==true" formArrayName="prior_deduct_attendancerule">
        <div class="col">
          <label for="" class="form-label required">Specify the priority to be deducted from the leave balance</label>
        </div>
        <ng-container *ngFor="let data of leave_prio().controls; let i=index;"
        [formGroupName]="i">
        <div class="col-12" >
            <div class="align-items-start d-flex flex-nowrap row row-16 {{i>0 ? 'mt-10':''}}">
                <div class="col form-row mt-0">
                  <ng-select appendTo="body"  class="form-ngselect" #ngSelector
                    formControlName="leave_type"
                    placeholder="{{this.messageService.selectplaceholddisplay('leave type')}}"
                    [ngClass]="{ 'is-invalid': (submitted || leave_prio().controls[i].get('leave_type')?.touched || leave_prio().controls[i].get('leave_type')?.dirty) &&   leave_prio().controls[i].get('leave_type')?.errors}"
                    (change)="duplicateLeaveType(i)"
                    >
                      <ng-option *ngFor="let leave of leaveTypes"   [value]="leave.id">{{leave.leave_name}}</ng-option>
                  </ng-select>
                  <div *ngIf="(submitted || leave_prio().controls[i].get('leave_type')?.touched || leave_prio().controls[i].get('leave_type')?.dirty) &&  leave_prio().controls[i].get('leave_type')?.errors" class="invalid-feedback">
                    <div *ngIf=" leave_prio().controls[i].get('leave_type')?.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                    <div *ngIf=" leave_prio().controls[i].get('leave_type')?.errors?.duplicate">The selected Leave Type is already selected</div>
                  </div>
                </div>
                <a class="flex-center sq-40 text-danger mt-0" (click)="deletePriority(i)" *ngIf="i!=0">
                    <i class="icon-trash "></i>
                </a>
            </div>
        </div>
        </ng-container>
        <div class="link-primary1 fs-14 fw-500" *ngIf="f.late_come_go_early_deduction.value==true" (click)="addLeaves();">
          <i class="icon-plus-square-fill me-8"></i>Add priority
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<footer class="submit-footer">
  <button *ngIf="disabled==false" class="btn btn-primary btn-sm ms-auto" [disabled]="!formGp.valid" (click)="validateForm()">NEXT</button>
  <button *ngIf="disabled==true" class="btn btn-primary btn-sm ms-auto"  (click)="validateForm()">NEXT</button>
</footer>
