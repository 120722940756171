import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HrITDeclarationService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  empletterList(page:any,limit:any,offset:any,search:any,filterparam:any,emp_id:any,submitkey:any,year:any,submit_type :any){
    let empid:any;
    if(emp_id == 0){
      empid = `&search=${search}`;
    }else{
      empid = `&employee_list=[${emp_id}]`
    }
    if(filterparam != undefined){
      return this.http.get(`${this.API_PATH}hr/api/v1/hr-it-declaration-list/?data=${page}&limit=${limit}&is_active=true&offset=${offset}&${filterparam}${empid}&proposed_confirm=${submitkey}&year=${year}&submit_type=${submit_type}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}hr/api/v1/hr-it-declaration-list/?data=${page}&limit=${limit}&is_active=true&offset=${offset}${empid}&ordering=&proposed_confirm=${submitkey}&year=${year}&submit_type=${submit_type}`, this.httpOption)
    }
  }

  uploadexcel(body: any,key:any){
   
    if(key=='HRA'){
      return this.http.post(`${this.API_PATH}hr/api/v1/hra-upload/`, body, this.httpOption);

    }else if(key=='80GG'){
      return this.http.post(`${this.API_PATH}hr/api/v1/gg-80-upload/`, body, this.httpOption);

    }else if(key=='Chapter VI A-80C'){
      return this.http.post(`${this.API_PATH}hr/api/v1/chapter6A-80C-upload/`, body, this.httpOption);

    }else if(key=='Chapter VI A-Deductions'){
      return this.http.post(`${this.API_PATH}hr/api/v1/chapter6A-deduction-upload/`, body, this.httpOption);

    }else if(key=='Regime Declaration'){
      return this.http.post(`${this.API_PATH}hr/api/v1/regime-upload/`, body, this.httpOption);

    }else if(key=='80G 100% exempted donation'){
      return this.http.post(`${this.API_PATH}hr/api/v1/donate-80g-100-upload/`, body, this.httpOption);

    }else if(key=='80G 50% exempted donation'){
      return this.http.post(`${this.API_PATH}hr/api/v1/donate-80g-50-upload/`, body, this.httpOption);

    }else if(key=='80GGA'){
      return this.http.post(`${this.API_PATH}hr/api/v1/gga-80-upload/`, body, this.httpOption);

    }else if(key=='Income/Loss from Let-out Property'){
      return this.http.post(`${this.API_PATH}hr/api/v1/letout-property-upload/`, body, this.httpOption);

    }else if(key=='Income/loss from self occupied property'){
      return this.http.post(`${this.API_PATH}hr/api/v1/self-occupied-property-upload/`, body, this.httpOption);

    }else {
      // if(key=='Income from other sources'
      return this.http.post(`${this.API_PATH}hr/api/v1/other-source-income-upload/`, body, this.httpOption);
    }
  }
}

