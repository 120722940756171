
<nav class="nav nav-custom sticky">
  <a
    class="nav-link"
    [ngClass]="{ active: activeToggle == 'proposed' }"
    (click)="selectItemList('proposed')"
  >
  Proposed Investment
  </a>
  <a
    class="nav-link"
    [ngClass]="{ active: activeToggle == 'confirmed' }"
    (click)="selectItemList('confirmed')"
  >
  Confirmed Investment
  </a>
</nav>
<div class="container-fluid p-24 " *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Investment Declaration</h3>
      <p class="text-helper mb-md-0">{{title}}</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon" style="width:22.6875rem;">
          <!-- [isLoading]="sLoading" -->
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='searchplaceholder' (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchemp($event)"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class=" btn-square btn btn-secondary" (click)="filterclick()" placement="bottom-left"
          id="dropdownBasic1" ngbDropdownToggle>
          <i class="icon-filter-left fs-13"></i>
          </button>
           <app-common-employee-filters *ngIf='filter === true' [filterForm]="filterForm" [from]="from" [filter]="filter"
            (filterClose)="closefn($event)"  (resetCalled)="resetCalledfn($event)" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
        </div>
        <button class="btn flex-shrink-0  btn-outline-primary  btn-icon btn-add" routerLink="/hr-investment-declaration/investment-declaration-import" *ngIf="activeToggle == 'proposed'">Import
          INVESTMENT</button>
          <div class=" d-inline-flex">
            <app-financial-year [(ngModel)]="selectedYear" (selectYear)="yearChange($event)"></app-financial-year>
          </div>
      </div>
    </div>
  </div>
  <div class="row row-16">

    <div class="col-12" *ngIf="!maintileloader">
      <div class="card card-c2">
        <div class="card-body p-24 d-flex gap-50 flex-wrap">
          <div class="statistic1 bg-primary">
            <span class="fs-32">{{itfulldata?.count}}</span><span class="text-uppercase fs-12">total employees</span>
          </div>
          <div class="statistic1 bg-accent1 pointer" (click)="investmentlist('true')">
            <span class="fs-32">{{itfulldata?.declared_count}}</span><span class="text-uppercase fs-12">INVESTMENT SUBMITTED</span>
            <button class="btn btn-outline-accent2 rounded-circle sq-24 p-0 fs-10"><i
                class="icon-chevron-right-lg"></i></button>
          </div>
          <div class="statistic1 bg-danger pointer" (click)="investmentlist('false')">
            <span class="fs-32">{{itfulldata?.count-itfulldata?.declared_count}}</span><span class="text-uppercase fs-12">INVESTMENT NOT SUBMITTED</span>
            <button class="btn btn-outline-accent2 rounded-circle sq-24 p-0 fs-10"><i
                class="icon-chevron-right-lg"></i></button>
          </div>

        </div>
      </div>
    </div>

    <div class="row row-16 mb-16" *ngIf="loader">
      <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
        *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
    </div>
  
    <div *ngIf="nodata && loader==false;else all_data" class="row " style="height: calc(100vh - 21rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg2.png'" [noDataText]="'NO DATA FOUND'"> </app-nodata-view>
    </div>

    <ng-template #all_data>
    <div class="row row-16rem " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let emplist of emplist let i = index">
          <div class="card card-c2 card-hover  p-16" (click)="viewemp(emplist?.id)">
            <div class="d-flex gap-12">
              <img alt="" class="img-fluid avatar-border avatar-circle sq-40"
                src="{{emplist?.profile_image}}" *ngIf="emplist?.profile_image !=  '' && emplist?.profile_image != null">
              <span class="avatar-sm me-8 rounded-circle sq-40 {{emplist?.color_code}}" *ngIf="emplist?.profile_image ==  ''|| emplist?.profile_image == null"><span>{{this.appService.getFirstChar(emplist?.first_name,2)}} </span></span>
              <div class="d-flex flex-column gap-1 overflow-hidden">
                <div class="fw-500 fs-14 text-trim">{{emplist?.first_name }} {{(emplist?.middle_name)?emplist?.middle_name:'' }} {{emplist?.last_name }} </div>
                <div class="hstack gap-5 text-light-400 fw-500 fs-10">
                  <div class="">{{emplist?.employee_code}}</div>|
                  <div class=" text-trim">{{emplist?.designation}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>
    </ng-template>
  </div>
</div>

  <div class="side-panel side-pane-active" style="--sidepanel-width:27rem;"
    [class.side-pane-active]='investmentSubmitted === true'>
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Investment{{submitnotsubmitkey?' ':' not '}}submitted </h5>
        <a class="toggle-panel" (click)="investmentSubmitted = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;" *ngIf="permissions">
        <div class="container-fluid p-32">
          <div class="row row-16">
            <div class="col-12 d-flex">
              <p class="text-uppercase fw-500 mb-0">Employee list</p>
              <a class="link-primary fs-14 ms-auto text-nowrap" (click)="generateTemplate()"><i class="icon-download me-1"></i>Download List</a>
            </div>
            <app-c1-loader *ngIf="loadersidebar"></app-c1-loader>
          <ng-container *ngIf="!loadersidebar">
            <div class="col-12" *ngFor="let emplist of downloadlist let i = index">
              <div class="card card-c2 card-hover  p-16">
                <div class="d-flex gap-12">
                  <img alt="" class="img-fluid avatar-border avatar-circle sq-40"
                  src="{{emplist?.profile_image}}" *ngIf="emplist?.profile_image !=  '' && emplist?.profile_image != null">
                  <span class="avatar-sm me-8 rounded-circle sq-40 {{emplist?.color_code}}" *ngIf="emplist?.profile_image ==  ''|| emplist?.profile_image == null"><span>{{this.appService.getFirstChar(emplist?.first_name,2)}} </span></span>
                  <div class="d-flex flex-column gap-1 overflow-hidden">
                    <div class="fw-500 fs-14 text-trim">{{emplist?.first_name }} {{(emplist?.middle_name)?emplist?.middle_name:'' }} {{emplist?.last_name }} </div>
                    <div class="hstack gap-5 text-light-400 fw-500 fs-10">
                      <div class="">{{emplist?.employee_code}}</div>|
                      <div class=" text-trim">{{emplist?.designation}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  