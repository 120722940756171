import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AttendanceRuleService } from 'src/app/@core/services/attendance-rule.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
@Component({
  selector: 'app-attendance-rule',
  templateUrl: './attendance-rule.component.html',
  styleUrls: ['./attendance-rule.component.scss']

})
export class AttendanceRuleComponent implements OnInit {
  viewDetail = false;
  deleteAlert = false;
  @ViewChild(SearchbarComponent) child:any;
  searchKeyword  = "";
  ruleDropdown:any[] = [];
  loader =  true;
  items:any[] = [];
  offset = 0;
  status:any = true;
  statusBtn:any = "Active";
  defaultlimit:any = 20;
  permissions:any = [];
  itemsList:any[] = [];
  infinityloader  = false;
  listlength = 0;
  direction = '';
  searchString = this.messageService.searchdisplay('Attendance Rule');
  nodata = false;
  deleteId = 0;
  viewData:any = []
  viewId=0
  deleteClicked =false
  vLoader = false

  workingDay:any;
  weekOff:any;
  holiday:any;
  nationalHoliday:any;
  halfDay:any;
  overtimeType:any
  isLoading = false
  searchData : Subject<void> = new Subject();

  constructor(private ars:AttendanceRuleService, public messageService : MessageService, public breakpointObserver: BreakpointObserver,public appService:AppService,public router: Router) { }

  ngOnInit(): void {
    this.getPermission();
    this.offset = 0;
    this.breakpointObserver
    .observe(['(min-width: 1024px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.defaultlimit = 40;

      }
    });
    this.getList();
    this.searchData.pipe(delay(1000),
    switchMap(()=> this.ars.getRuleList(this.status,this.defaultlimit,this.offset,this.searchKeyword)
    )).subscribe((result:any)=>{
      this.arrangeApiData(result)
    })
  }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
  }

  getList(){
    this.ars.getRuleList(this.status,this.defaultlimit,this.offset,this.searchKeyword).subscribe((result: any) => {
      this.arrangeApiData(result)
    });
  }

  arrangeApiData(result:any){
    this.isLoading = false
    let res = result.body;
    this.itemsList = res.results;
    this.listlength = res.results.length;
    if(this.offset ==0){
      this.items = [];
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.loader = false;
    this.nodata = res.count==0?true:false;
  }

  filterRule(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.items = [];
      this.searchData.next();
    }else{
      this.searchData.next();
    }
  }
  /********* STATUS FILTER **************/
  selectItemList(value:any){
    this.loader = true;
    this.status = value;
    this.offset = 0;
    this.items = [];
    // this.getList();
    this.child.clearBar();
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.items.push(this.itemsList[i]);
    }
  }
  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDown() {
    this.infinityloader = true;
    this.offset += this.defaultlimit;
    if(this.listlength){
      this.getList();
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/
  editRule(id:number){
    this.router.navigate(['/attendance-rule/attendance-rule-setup/'+id]);
  }
  viewDetailPanel(id:any){
    if(this.permissions.v){
      this.vLoader = true
      this.viewDetail = true;
      this.ars.getRuleDetail(id).subscribe((res: any) => {
        this.viewData = res.body;
        this.viewId = id;
        let overtime_rate = this.viewData.overtime_rates
        var working =  overtime_rate.filter(function(working:any) {
          return working.overtime_type == "Working Day"
        });
        var weekoff =  overtime_rate.filter(function(weekoff:any) {
          return weekoff.overtime_type == "Week Off"
        });
        var holiday =  overtime_rate.filter(function(holiday:any) {
          return holiday.overtime_type == "Holiday"
        });
        var national =  overtime_rate.filter(function(national:any) {
          return national.overtime_type == "National Holiday"
        });
        var halfday =  overtime_rate.filter(function(halfday:any) {
          return halfday.overtime_type == "Half Day"
        });

        this.weekOff            = weekoff;
        this.workingDay         = working;
        this.holiday            = holiday;
        this.nationalHoliday    = national
        this.halfDay            = halfday;
        this.prepareViewData();
      })
    }
  }
  ordinal_suffix_of(i:number) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
  }
  prepareViewData(){
    this.viewData.attend_rule_desc = this.viewData.attend_rule_desc != null ? this.viewData.attend_rule_desc :'-';
    this.viewData.calculate_total_work = this.viewData.calculate_total_work==false?'First check-in and last check-out':'Every valid check-in and check-out'
    this.viewData.weekoff_paid = this.viewData.weekoff_paid == true ? 'Yes':'No';
    this.viewData.arrear_upto = this.viewData.arrear_upto !== null ? this.viewData.arrear_upto : '-'
    this.viewData.attendance_cycle = this.viewData.attendance_cycle == false?'Manual':'Calendar'
    this.viewData.attendance_calculate = this.viewData.attendance_calculate == false?'Actual days':'Optimistic'
    this.viewData.start_day = this.viewData.start_day !== null ? this.viewData.start_day : '-'
    this.viewData.end_day = this.viewData.end_day !== null ? this.viewData.end_day : '-'
    this.viewData.mark_absent_weekoff = this.viewData.mark_absent_weekoff == true? 'Yes':'No'
    this.viewData.mark_absent_holiday = this.viewData.mark_absent_holiday == true? 'Yes':'No'

    this.viewData.late_coming_penalty = this.viewData.late_coming_penalty == true? 'Yes':'No'
    if(this.viewData.late_coming_penalty === "Yes"){
      this.viewData.late_come_go_early_deduction = this.viewData.late_come_go_early_deduction == false ? 'Attendance' : 'Leave'
      this.viewData.deduction_type = this.viewData.deduction_type == false? 'Half Day' : 'Full Day'
      this.viewData.grace_day_come_late_go_early = this.viewData?.grace_day_come_late_go_early
      if(this.viewData.late_come_go_early_deduction === 'Attendance'){
        this.viewData.prior_deduct_attendancerule = []
      }
    }else{
      this.viewData.late_come_go_early_deduction = '-'
      this.viewData.deduction_type = '-'
      this.viewData.grace_day_come_late_go_early = '-'
      this.viewData.prior_deduct_attendancerule = []
    }

    this.viewData.enable_compoff = this.viewData.enable_compoff == true? 'Yes':'No'
    if(this.viewData.enable_compoff == "Yes"){
      this.viewData.min_hour_full_day = this.viewData.min_hour_full_day !== null ? this.appService.formatTime(this.viewData.min_hour_full_day) : "-"
      this.viewData.min_hour_half_day = this.viewData.min_hour_half_day !== null ? this.appService.formatTime(this.viewData.min_hour_half_day) : "-"
      this.viewData.auto_initiate_extra_working_benefit = this.viewData.auto_initiate_extra_working_benefit == true?'Yes':'No'
      this.viewData.comp_off_workdays = this.viewData.comp_off_workdays == true?'Yes':'No'
      if(this.viewData.comp_off_workdays == 'No'){
        this.viewData.comp_off_eligibility_fulldays = '-'
      }else{
        this.viewData.comp_off_eligibility_fulldays = this.viewData.comp_off_eligibility_fulldays?.replace('actual','')
      }
      this.viewData.comp_off_expiry = this.viewData.comp_off_expiry == true? 'Yes':'No'
      this.viewData.comp_off_weekoff = this.viewData.comp_off_weekoff == true ? 'Yes' : 'No'
      this.viewData.comp_off_holiday = this.viewData.comp_off_holiday == true ? 'Yes' : 'No'
      this.viewData.comp_off_nationalholiday = this.viewData.comp_off_nationalholiday == true ? 'Yes' : 'No'
      this.viewData.comp_off_halfday_work = this.viewData.comp_off_halfday_work == true ? 'Yes' : 'No'
      if(this.viewData.comp_off_halfday_work == 'No'){
        this.viewData.comp_off_eligibility_halfdays = '-'
      }else{
        this.viewData.comp_off_eligibility_halfdays = this.viewData.comp_off_eligibility_halfdays?.replace('Actual','')
      }
    }else{
      this.viewData.min_hour_full_day = "-"
      this.viewData.min_hour_half_day = "-"
      this.viewData.auto_initiate_extra_working_benefit = "-"
      this.viewData.comp_off_workdays = "-"
      this.viewData.comp_off_expiry = "-"
      this.viewData.after_how_many_days = '-'
      this.viewData.comp_off_workdays = '-'
      this.viewData.comp_off_weekoff = '-'
      this.viewData.comp_off_holiday = '-'
      this.viewData.comp_off_nationalholiday ='-'
      this.viewData.comp_off_halfday_work = '-'
      this.viewData.comp_off_eligibility_fulldays = '-'
      this.viewData.comp_off_eligibility_halfdays = '-'
    }

    this.viewData.enable_overtime = this.viewData.enable_overtime == true? 'Yes':'No'
    this.viewData.is_regular_attendance_calculation_ot = this.viewData.is_regular_attendance_calculation_ot == true? 'Yes':'No'
    this.viewData.is_regular_attendance_calculation_ot_halfday = this.viewData.is_regular_attendance_calculation_ot_halfday == true? 'Yes':'No'

    if(this.viewData.enable_overtime == "Yes"){
      this.viewData.min_hour_required_consider_overtime_day = this.viewData.min_hour_required_consider_overtime_day != null ? this.appService.formatTime(this.viewData.min_hour_required_consider_overtime_day) : "-"
      this.viewData.set_overtime_workday = this.viewData.set_overtime_workday == true? 'Yes':'No'
      this.viewData.max_overtime_hour_allow_working_day = this.viewData.max_overtime_hour_allow_working_day != null ? this.appService.formatTime(this.viewData.max_overtime_hour_allow_working_day) : "-"
      if(this.viewData.set_overtime_workday == 'No'){
        this.viewData.overtime_eligibility_fullday = '-'
        this.viewData.max_overtime_hour_allow_working_day = "-"
        this.viewData.rate_working_days = '-'
      }else{
        this.viewData.overtime_eligibility_fullday = this.viewData.overtime_eligibility_fullday?.replace('actual','')
      }
      this.viewData.max_overtime_hour_allow_weekoff = this.viewData.max_overtime_hour_allow_weekoff != null ? this.appService.formatTime(this.viewData.max_overtime_hour_allow_weekoff) : "-"
      this.viewData.max_overtime_hour_allow_holiday = this.viewData.max_overtime_hour_allow_holiday != null ? this.appService.formatTime(this.viewData.max_overtime_hour_allow_holiday) : "-"
      this.viewData.max_overtime_hour_allow_nationalholiday = this.viewData.max_overtime_hour_allow_nationalholiday != null ? this.appService.formatTime(this.viewData.max_overtime_hour_allow_nationalholiday) : "-"
      this.viewData.max_overtime_hour_allow_halfday_working = this.viewData.max_overtime_hour_allow_halfday_working != null ? this.appService.formatTime(this.viewData.max_overtime_hour_allow_halfday_working) : '-'
      this.viewData.set_overtime_weekoff = this.viewData.set_overtime_weekoff == true ? 'Yes' : 'No'
      if(this.viewData.set_overtime_weekoff == 'No'){
        this.viewData.max_overtime_hour_allow_weekoff = "-"
        this.viewData.rate_weekoff = '-'
      }
      this.viewData.set_overtime_holiday = this.viewData.set_overtime_holiday == true ? 'Yes' : 'No'
      if(this.viewData.set_overtime_holiday == 'No'){
        this.viewData.max_overtime_hour_allow_holiday = "-"
        this.viewData.rate_holiday = '-'
      }
      this.viewData.set_overtime_nationalholiday = this.viewData.set_overtime_nationalholiday == true ? 'Yes' : 'No'
      if(this.viewData.set_overtime_nationalholiday == 'No'){
        this.viewData.max_overtime_hour_allow_nationalholiday = "-"
        this.viewData.rate_nationalholiday = '-'
      }
      this.viewData.set_overtime_halfday_work = this.viewData.set_overtime_halfday_work == true ? 'Yes' : 'No'
      if(this.viewData.set_overtime_halfday_work == 'No'){
        this.viewData.overtime_eligibility_halfday = '-'
        this.viewData.max_overtime_hour_allow_halfday_working = '-'
        this.viewData.rate_halfday_working = '-'
      }else{
        this.viewData.overtime_eligibility_halfday = this.viewData.overtime_eligibility_halfday?.replace('Actual','')
      }
    }else{
      this.viewData.min_hour_required_consider_overtime_day = "-"
      this.viewData.set_overtime_workday = "-"
      this.viewData.max_overtime_hour_allow_working_day = "-"
      this.viewData.max_overtime_hour_allow_weekoff = "-"
      this.viewData.max_overtime_hour_allow_holiday = "-"
      this.viewData.max_overtime_hour_allow_nationalholiday = "-"
      this.viewData.overtime_eligibility_fullday = '-'
      this.viewData.rate_working_days = '-'
      this.viewData.set_overtime_weekoff = '-'
      this.viewData.rate_weekoff = '-'
      this.viewData.rate_holiday = '-'
      this.viewData.rate_nationalholiday = '-'
      this.viewData.rate_halfday_working = '-'
      this.viewData.overtime_eligibility_halfday = '-'
      this.viewData.set_overtime_holiday = '-'
      this.viewData.set_overtime_halfday_work = '-'
      this.viewData.set_overtime_nationalholiday = '-'
      this.viewData.max_overtime_hour_allow_halfday_working = '-'
    }

    this.viewData.send_absent_email = this.viewData.send_absent_email == true? 'Yes':'No'
    if(this.viewData.send_absent_email == 'Yes'){
      this.viewData.trigger_absent_continuously_absent = this.viewData.trigger_absent_continuously_absent
      this.viewData.whome_want_notify = this.viewData.whome_want_notify
    }else{
      this.viewData.trigger_absent_continuously_absent = '-'
      this.viewData.whome_want_notify = []
    }
    this.viewData.send_weekly_absent_email = this.viewData.send_weekly_absent_email == true? 'Yes':'No'
    if(this.viewData.send_weekly_absent_email == 'Yes'){
      this.viewData.weekly_notify_cc = this.viewData.weekly_notify_cc
    }else{
      this.viewData.weekly_notify_cc = []
    }
    this.viewData.send_monthly_absent_email = this.viewData.send_monthly_absent_email == true? 'Yes':'No'
    if(this.viewData.send_monthly_absent_email == 'Yes'){
      this.viewData.monthly_notify_cc = this.viewData.monthly_notify_cc
    }else{
      this.viewData.monthly_notify_cc = []
    }
    this.viewData.applicable = this.viewData.attendance_rule_assignright[0].applicable;
    this.viewData.applicableTo = [];
    this.viewData.attendance_rule_assignright.forEach((item:any, key:any) => {
      if(this.viewData.applicable == "Company"){
      let compName = item.company_name;
      let compImg  = item.company_logo;
      this.viewData.applicableTo.push({'name': compName,'img': compImg})
      }else if(this.viewData.applicable == "Department")
        this.viewData.applicableTo.push(item.department_name);
      else if(this.viewData.applicable == "Designation")
        this.viewData.applicableTo.push(item.designation_name);
      else if(this.viewData.applicable == "Employee"){
      let empName = item.employee_name.name+" ("+item.employee_name.employee_code+")";
      let empImg  =  item.employee_name.profile_image;
      let color_code  =  item.employee_name.color_code;
      this.viewData.applicableTo.push({'name': empName,'img': empImg,'color_code':color_code})
      }else if(this.viewData.applicable == "Grade")
        this.viewData.applicableTo.push(item.grade_name);
      else if(this.viewData.attendance_rule_assignright[0].applicable == "BU")
        this.viewData.applicableTo.push(item.businessunit_name);
    });
    if(this.viewData.applicable == "BU")
    this.viewData.applicable = "Business Unit"
    this.SidepanelScrollTop();
  }

  deleteRule(id:number){
    this.deleteClicked =true
    let data = { "is_active": false};
    this.ars.deleteRule(data,id).subscribe((res: any) => {
      this.deleteAlert = false;
      this.loader = true;
      this.items = [];
      this.getList();
      this.deleteClicked =false
    });
  }
  createShiftDisplay(aShiftMap:any){
    let string:any =[];
    aShiftMap.forEach((item:any, key:any) => {
      string.push(item.shift_mapping_name);
    })
    return string.join(", ");
  }
  SidepanelScrollTop() {
      var list = document.getElementsByClassName('side-panel-body');
      for (var i = 0; i < list.length; i++) {
        list[i].scrollTo(0, 0)
      }
      this.vLoader = false
  }
  searchattendance(searchKeyword:any){
    this.loader = true;
    this.isLoading = true
    this.offset            = 0;
    this.items = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next();
    }
  }

  // No Data
  addNewdata(){
    this.router.navigate(['/attendance-rule/attendance-rule-setup']);
  }

  // Hours
  getHours(hours:any){
    return moment(hours,'HH:mm:ss').format('H');
  }
}
