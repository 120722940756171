import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function HalfFullDayValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      if (value === null || value === undefined) {
        return null;
      }
      const isValid = !isNaN(value) && value >= 0.5 && (value * 10) % 5 === 0;
      return isValid ? null : { invalidDayDuration: true };
  }
}

