import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { maxTenDigitDecimal } from 'src/app/@shared/validators/maxTenDigitDecimal.validators'
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { singleDigitDecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { HalfFullDayValidator } from 'src/app/@shared/validators/halfFullDay.validators';

@Component({
  selector: 'app-leave-request-criteria',
  templateUrl: './leave-request-criteria.component.html',
  styleUrls: ['./leave-request-criteria.component.scss']
})
export class LeaveRequestCriteriaComponent implements OnInit {

  constructor(public messageService : MessageService,public appService: AppService,public existDataService : ExistDatasService,public fb : FormBuilder,public leavetypeService:leavetypeService, public cd : ChangeDetectorRef) { }

  @Input() leaveConfigForm: any;
  @Input() leaveTypeCode: any;
  @Input() leaveTypeName: any;
  @Input() leaveRequestCriteriaForm : any;
  @Input() saveBtnClicked: any;

  activeIndex:any = '';
  leaveType:any = [];
  submitted = false;

  ngOnInit(): void {
    for(let i=0;i<this.leaveRequestCriteriaForm.controls?.length;i++){
      if(this.leaveTypeCode==this.leaveRequestCriteriaForm.controls[i].get('leave_code')?.value){
        this.activeIndex = i;
      }
    }
    this.LeavemapFunction()
    this.addValidator();
  }

  // Function for listing leave types
LeavemapFunction(){
  this.leavetypeService.getLeaveDropdown().subscribe((res: any) => {
    this.leaveType  = res
    for(let i=0;i<this.leaveType.length;i++){
      if(this.leaveType[i]['leave_code']==this.leaveTypeCode){
        this.leaveType.splice(i,1)
      }
    }
    this.selectAllForDropdownItems(this.leaveType)
  });

}

 // Select All
 selectAllForDropdownItems(items: any[]) {
  let allSelect = (items: any[]) => {
    items.forEach(element => {
      element['selectedAllGroup'] = 'selectedAllGroup';
    });
  };
  allSelect(items);
}

clearcommonForm(index:any,criteriaIndex:any){
    let daata = this.leaveRequestCriteriaForm.controls[criteriaIndex].controls.leave_clubbing_leave_type.value;
    daata.splice(index, 1);
    this.leaveRequestCriteriaForm.controls[criteriaIndex].controls.leave_clubbing_leave_type.patchValue(daata);
}

addValidator(){
  this.setvalidators('backdated_leave_allow','backdated_days','decimal')
  this.setvalidators('doc_required_leave','leave_exceed','decimal')
  this.setvalidators('leave_clubbing_not_allowed_status','leave_clubbing_leave_type','')
}

setvalidators(parent:any,control:any,patternType:any){
  for(let i=0;i<this.leaveRequestCriteriaForm.length;i++){
    if(this.leaveRequestCriteriaForm.controls[i].get(parent).value==true){
        if(patternType=='number'){
          this.leaveRequestCriteriaForm.controls[i].get(control)?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(10)])
        }
        else{
          if(parent!='leave_clubbing_not_allowed_status')
          this.leaveRequestCriteriaForm.controls[i].get(control)?.setValidators([Validators.required,singleDigitDecimalValidator(),maxTenDigitDecimal(), Validators.min(0.5)])
          else
          this.leaveRequestCriteriaForm.controls[i].get(control)?.setValidators([Validators.required])
        }
    }
    else{
        this.leaveRequestCriteriaForm.controls[i].get(control)?.clearValidators();
        this.leaveRequestCriteriaForm.controls[i].get(control)?.setValue('');
      }
    this.leaveRequestCriteriaForm.controls[i].get(control)?.updateValueAndValidity()
  }
}

  get f() { return this.leaveRequestCriteriaForm.controls; }

  leave_prio() : FormArray{
    return this.leaveRequestCriteriaForm.at(this.activeIndex).get('leaverequest_priordays') as FormArray
  }

  createLeaveFormGroup(): FormGroup {
    return this.fb.group({
      'leaveduration': new FormControl(null,[Validators.required, HalfFullDayValidator(),Validators.max(365)]),
      'priordays': new FormControl(null,[Validators.required, Validators.min(1),Validators.max(365)]),
      'beforedays': new FormControl(null,[Validators.required, Validators.min(1), Validators.max(365)])
    })
  }

  addLeaves(){
    this.leave_prio().push(this.createLeaveFormGroup())
  }

  deletePriority(index:number){
    this.leave_prio().removeAt(index);
  }

  deleteFullLeave(){
    this.leave_prio().clear()
  }

  setPriorValidation(index: any) {
    let prior = this.leave_prio().at(index).get('priordays');
    let leave_request = this.leave_prio().at(index).get('beforedays');
    if (prior?.value != null && leave_request?.value != null) {
      if (Number(prior?.value) >= Number(leave_request?.value)) {
        prior?.setErrors({'maxVal': true });
        prior.markAsTouched();
      } else {
        prior.setErrors(null, {emitEvent : false});
        prior.updateValueAndValidity()
      }
    }
  }

  onLeaveDurationKeyUp(index: number) {
    const currentControl = this.leave_prio().at(index).get('leaveduration');
    const currentValue = currentControl?.value
    const isDuplicate = this.leave_prio().controls.some((control, idx) =>
      idx !== index && parseFloat(control.get('leaveduration')?.value) === parseFloat(currentValue)
    );
    if (isDuplicate) {
      currentControl?.setErrors({ 'duplicate': true });
    } else {
      currentControl?.setErrors(null);
      currentControl?.updateValueAndValidity();
    }
  }

  notanumber(val: any) {
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }
}
