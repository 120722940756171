<div class="container-fluid p-24">
  <div class="row row-16  ">
    <div class="col-md-4 col-lg-6">
      <h3>Confirmed Declaration</h3>
      <p class="text-helper mb-md-0">The approved confirmed investment declarations are listed here.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end gap-16">
        <div class=" d-inline-flex ms-auto">

          <app-financial-year [(ngModel)]="selectedYear" (selectYear)="yearChange($event)"></app-financial-year>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="flatRate && from!='HR'">
      <div role="alert" class="alert alert-info mb-0 {{alertShowflatRate}}">
        <p class="mb-0 fs-14"><span class="">Note : </span>IT declaration is restricted for your employment type, please contact HR for more details.</p>
        <div class="btn-close pointer" (click)="alertShowflatRate='hide'"></div>
      </div>
    </div>
    <div class="col-12" *ngIf="(proposedData.length != 0) && from!='HR'">
      <div role="alert" class="alert alert-warning mb-0 {{alertShow}}">
        <p class="mb-0 fs-14"><span class="text-warning">Note : </span>This is approved confirmed investment
          declaration. To make any changes to the declaration kindly visit the request section.</p>
        <div class="btn-close pointer" (click)="alertShow='hide'"></div>
      </div>
    </div>
    <div class="col-12" *ngIf="(!loader && proposedData?.length != 0 && (considerConfirm != null && considerConfirm != ''))">
      <div role="alert" class="alert alert-info mb-0 {{alertShowConfirm}}">
        <p class="mb-0 fs-14"><span class="fw-600">Note : </span>Income tax is deducted according to the confirmed investment declaration from {{considerConfirm}}. Please check the confirmed investment declaration page for tax computation based on accepted investment documentation.</p>
        <div class="btn-close pointer" (click)="alertShowConfirm='hide'"></div>
      </div>
    </div>
    <div *ngIf="(proposedData.length == 0 && !loader);else all_data" class="row" style="height: calc(100vh - 12rem);">
      <app-nodata-view class="vstack" [noDataImg]="'bg22.png'" [noDataText]="'Explore approved investment details!'"
        [noDataPara]="'It seems there are no approved confirmed investment declarations yet. Once your submissions are approved, they will appear here for your review.'"
        [hasPermission]="false">
      </app-nodata-view>
    </div>
    <app-table-loader *ngIf="loader"></app-table-loader>
    <ng-template #all_data>
      <ng-container *ngIf="!loader">
        <div class="col-lg-3 col-md-4 col-sm-5 col-12" style="height: calc(100vh - 19rem);">
          <div class="card card-c2 p-0 shadow-0 radius-4  stick-block overflow-auto h-100">
            <div class=" card-body px-0 py-24 ">
              <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab1" class=" vetical-tab tab-scroll">
                <li [ngbNavItem]="1">
                  <a ngbNavLink class="tab-link ">
                    <span class="text-trim">Tax Summary</span>
                  </a>
                  <ng-template ngbNavContent class="h-100">
                    <div class="row row-16">
                      <div class="col-12 d-flex">
                        <div class="fs-18 fw-500">{{proposedData[0]?.tax_regime?'New Regime':'Old Regime'}}</div> <span
                          class="badge badge-success ms-8 px-12 fs-10"> ACTIVE DECLARATION</span>
                      </div>
                      <div class="col-12" *ngIf="slabRate">
                        <div role="alert" class="alert alert-danger mb-0 {{alertShowslabRate}}">
                          <p class="mb-0 fs-14"><span class="fw-600">Note : </span>Income tax will be deducted at a higher slab as PAN is not available.</p>
                          <div class="btn-close pointer" (click)="alertShowslabRate='hide'"></div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="card card-c2 card-highlight highlight2 h-100">
                          <div
                            class="card-body p-16 justify-content-center d-flex flex-column">
                            <h6 class="fw-500 fs-20 mb-0">{{taxRegimeData?.regime_summary?.total_tax_income | currency:
                              currency}}</h6>
                            <hr class="hr-1 mb-16 mt-16 w-100">
                            <div class="fw-500 fs-12">Total taxable income
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="card card-c2 card-highlight highlight1 h-100">
                          <div
                            class="card-body p-16 justify-content-center d-flex flex-column">
                            <h6 class="fw-500 fs-20 mb-0">{{taxRegimeData?.tax_details?.total_payable_tax | currency:
                              currency}}</h6>
                            <hr class="hr-1 mb-16 mt-16 w-100">
                            <div class="mb-0 fw-500 fs-12">Total tax payable
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div class="card card-c2 card-highlight highlight5 h-100">
                          <div
                            class="card-body p-16 justify-content-center d-flex flex-column">
                            <h6 class="fw-500 fs-20 mb-0">{{taxRegimeData?.tax_payable?.tax_paid_so_far_sum | currency:
                              currency}}</h6>
                            <hr class="hr-1 mb-16 mt-16 w-100">
                            <div class="mb-0 fw-500 fs-12">Tax already paid
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="card card-c2 card-highlight highlight3 h-100">
                          <div
                            class="card-body p-16 justify-content-center d-flex flex-column">
                            <h6 class="fw-500 fs-20 mb-0">{{taxRegimeData?.tax_payable?.tax_balance_payable_sum | currency:
                              currency}}</h6>
                            <hr class="hr-1 mb-16 mt-16 w-100">
                            <div class="mb-0 fw-500 fs-12">Balance payable
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row row-12 mt-16">
                      <div class="col-12 d-flex">
                        <div class="fs-18 fw-500">Tax Payable </div>
                        <div class="fs-14 fw-500 ms-auto link-primary1" (click)="detailedTaxCalculation = true"><i
                            class="bi bi-eye me-1"></i>Detailed Tax calculation</div>
                      </div>
                      <div class="col-12">
                        <ngb-accordion class="custom-accordion1 border-0" #acc="ngbAccordion" activeIds="ngb-panel-0">
                          <ngb-panel id="ngb-panel-1">
                            <ng-template ngbPanelHeader>
                              <button ngbPanelToggle class="accordion-button fw-500">
                                Tax paid so far
                                <div class="ms-auto fw-500">{{taxRegimeData?.tax_payable?.tax_paid_so_far_sum |
                                  currency: currency}}</div>
                              </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <ng-container *ngIf="taxRegimeData?.tax_payable?.tax_paid_so_far?.length > 0">
                                <div class="d-flex flex-column gap-12 fs-14">
                                  <div *ngFor="let taxPaid of $any(taxRegimeData?.tax_payable?.tax_paid_so_far)"
                                    class="d-flex w-100">{{taxPaid?.month == 'tax_paid_by_prev_employer'?'Tax paid till now':taxPaid?.month}}<div class="ms-auto ">{{taxPaid.tax | currency:currency}}</div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-template>
                          </ngb-panel>
                        </ngb-accordion>
                      </div>
                      <div class="col-12 ">
                        <div class="box-1 py-16 fw-500 ">Tax paid so far<div class="ms-auto ">
                            {{taxRegimeData?.tax_payable?.tax_paid_so_far_sum | currency: currency}}</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <ngb-accordion class="custom-accordion1 border-0" #acc="ngbAccordion" activeIds="ngb-panel-0">
                          <ngb-panel id="ngb-panel-1">
                            <ng-template ngbPanelHeader>
                              <button ngbPanelToggle class="accordion-button fw-500">
                                Balance payable
                                <div class="ms-auto fw-500">{{taxRegimeData?.tax_payable?.tax_balance_payable_sum |
                                  currency: currency}}</div>
                              </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <ng-container *ngIf="taxRegimeData?.tax_payable?.tax_paid_so_far?.length > 0">
                                <div class="d-flex flex-column gap-12 fs-14">
                                  <div class="d-flex w-100"
                                    *ngFor="let taxBalance of $any(taxRegimeData?.tax_payable?.tax_balance_payable)">
                                    {{taxBalance?.month}}<div class="ms-auto ">{{taxBalance?.tax | currency: currency}}
                                    </div>
                                  </div>

                                </div>
                              </ng-container>
                            </ng-template>
                          </ngb-panel>
                        </ngb-accordion>
                      </div>
                      <div class="col-12">
                        <div class="box-1 py-16 fw-500 ">Total tax payable in upcoming months<div class="ms-auto ">
                            {{taxRegimeData?.tax_payable?.tax_balance_payable_sum | currency: currency}}</div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink class="tab-link ">
                    <span class="text-trim">HRA/80GG</span>
                  </a>
                  <ng-template ngbNavContent class="h-100">
                    <div class="row row-12 ">
                      <div class="col-12">
                        <div class="card details-card indicater1 px-16 py-12">
                          <div class="row">
                            <div class="col-5">
                              <div class="fw-600 fs-14 text-uppercase">HRA/80GG</div>
                            </div>
                            <div class="col" *ngIf="!taxRegimeData?.earnings?.is_hra_present_in_salary">
                              <div class="detail-title">Maximum limit</div>
                              <div class="detail-desc">{{proposedData[0]?.hra80gg_limit | currency: currency}}</div>
                            </div>
                            <div class="col">
                              <div class="detail-title">Total amount declared</div>
                              <div class="detail-desc"> {{taxRegimeData?.declared_amount?.hra80gg |
                                currency: currency}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mt-16 d-flex">
                        <div class=" fw-500">HRA/80GG</div>
                      </div>
                      <ng-container
                        *ngIf="(proposedData[0]?.hra80gg?.length > 0 && taxRegimeData?.earnings?.is_hra_present_in_salary)">
                        <div class="col-12">
                          <ngb-accordion class="custom-accordion1 border-0" #acc="ngbAccordion" activeIds="ngb-panel-0">
                            <ngb-panel id="ngb-panel-1">
                              <ng-template ngbPanelHeader>
                                <button ngbPanelToggle class="accordion-button fw-500">
                                  HRA
                                  <span class="ms-auto">{{taxRegimeData?.declared_amount?.hra_data?.rent_paid |
                                    currency: currency}}</span>
                                </button>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <div class="d-flex flex-column gap-12 fs-14 text-light-500">


                                  <!-- New start -->
                                  <ng-container *ngFor="let hra80gg of proposedData[0]?.hra80gg,let i = index">
                                    <div class="d-flex w-100">Property{{i+1}}<div class="ms-auto ">{{hra80gg?.amount |
                                        currency: currency}}</div>

                                    </div>
                                    <div style="width:14.438rem;">
                                      <div class="card card-c2  bg-secondary">
                                        <div class="p-12 bg-secondary d-flex align-item-center">

                                          <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                          <div
                                            class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                            <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                              this.appService.getDocName(hra80gg?.landlord_document) }}</span>
                                            <span class="tips-helper ">{{
                                              this.appService.getDocName(hra80gg?.landlord_document) }}</span>

                                            <div class="d-flex gap-8">
                                              <div class="link-primary"
                                                (click)="viewDocument(hra80gg?.landlord_document)">
                                                <i class="bi bi-eye me-1"></i>View
                                              </div>
                                              <div class="link-primary"
                                                (click)="this.appService.downloadFile(hra80gg?.landlord_document,this.appService.getDocName(hra80gg?.landlord_document))">
                                                <i class="bi bi-download me-1"></i>Download
                                              </div>
                                            </div>
                                          </div>

                                        </div>

                                      </div>
                                    </div>
                                  </ng-container>
                                  <!-- New End -->

                                </div>
                              </ng-template>
                            </ngb-panel>
                          </ngb-accordion>
                        </div>
                        <div class="col-12 ">
                          <div class="box-1 py-16 fw-500 ">Total expemtions under section 10<div class="ms-auto ">
                              {{taxRegimeData?.excemption_declaration?.hra | currency: currency}}</div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="proposedData[0]?.hra80gg?.length > 0 && !taxRegimeData?.earnings?.is_hra_present_in_salary">
                        <div class="col-12">
                          <div class="card card-c2  px-24 py-16 ">
                            <div class="row row-12">
                              <div class="col-12 d-flex">
                                <p class="mb-0 me-24"> 80GG</p><span
                                  class="ms-auto fw-500">{{proposedData[0]?.hra80gg[0]?.amount | currency:
                                  currency}}</span>
                              </div>
                              <div style="width:14.438rem;">
                                <div class="card card-c2  bg-secondary">
                                  <div class="p-12 bg-secondary d-flex align-item-center">

                                    <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                    <div
                                      class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                        this.appService.getDocName(proposedData[0]?.hra80gg[0]?.landlord_document)
                                        }}</span>
                                      <span class="tips-helper ">{{
                                        this.appService.getDocName(proposedData[0]?.hra80gg[0]?.landlord_document)
                                        }}</span>

                                      <div class="d-flex gap-8">
                                        <div class="link-primary"
                                          (click)="viewDocument(proposedData[0]?.hra80gg[0]?.landlord_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary"
                                          (click)="this.appService.downloadFile(proposedData[0]?.hra80gg[0]?.landlord_document,this.appService.getDocName(proposedData[0]?.hra80gg[0]?.landlord_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 ">
                          <div class="box-1 py-16 fw-500 ">Total expemtions under section 10<div class="ms-auto ">
                              {{taxRegimeData?.excemption_declaration?.['80gg'] | currency: currency}}</div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="proposedData[0]?.hra80gg?.length == 0">
                        <div class="col-12 ">
                          <div class="box-1 py-16 fw-500 ">Total expemtions under section 10<div class="ms-auto ">{{0|
                              currency: currency}}</div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="3">
                  <a ngbNavLink class="tab-link ">
                    <span class="text-trim">Chapter VI A - 80 C</span>
                  </a>
                  <ng-template ngbNavContent class="h-100">
                    <div class="row row-12 ">
                      <div class="col-12">
                        <div class="card details-card indicater1 px-16 py-12">
                          <div class="row">
                            <div class="col-5">
                              <div class="fw-600 fs-14 text-uppercase">CHAPTER VI A - 80 C</div>
                            </div>
                            <div class="col">
                              <div class="detail-title">Maximum limit</div>
                              <div class="detail-desc">{{proposedData[0]?.chapter4a80c_limit | currency: currency}}
                              </div>
                            </div>
                            <div class="col">
                              <div class="detail-title">Total amount declared</div>
                              <div class="detail-desc"> {{taxRegimeData?.declared_amount?.chapter4a80c | currency:
                                currency}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mt-16 d-flex align-items-end">
                        <div class=" fw-500">Chapter VI A - 80 C</div>
                        <div class="text-light-400 fs-14 fw-500 ms-8"> (Max limit - 1,50,000)</div>
                      </div>


                      <!-- New start -->
                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.employee_contribute_pf">
                        <div class="card card-c2 px-24 py-16 flex-row">
                          Employee contribution towards PF
                          <div class="fw-500 ms-auto">{{proposedData[0]?.chapter4a80c?.employee_contribute_pf_amount |
                            currency: currency}}</div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.contribution_vpf">
                        <div class="card card-c2 px-24 py-16 flex-row">
                          Contribution towards VPF
                          <div class="fw-500 ms-auto">{{proposedData[0]?.chapter4a80c?.contribution_vpf_amount |
                            currency: currency}}</div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.contribution_15y_ppf">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24"> Contribution towards 15 years PPF</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.ppf_amount | currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.ppf_document) }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.ppf_document) }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.ppf_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.ppf_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.ppf_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.previous_employer_pf">
                        <div class="card card-c2 px-24 py-16 flex-row">
                          Previous employer PF
                          <div class="fw-500 ms-auto">{{proposedData[0]?.chapter4a80c?.previous_employer_pf_amount |
                            currency: currency}}</div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.five_year_POTD">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Five-year post office time deposit (POTD) Scheme</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.POTD_amount | currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.POTD_document) }}</span>
                                    <span
                                      class="tips-helper ">{{this.appService.getDocName(proposedData[0]?.chapter4a80c?.POTD_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.POTD_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.POTD_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.POTD_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.subscribe_national_saving">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Subscription to national saving scheme (NSC)</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.nsc_amount| currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span
                                      class="tips text-uppercase fs- fw-700 text-trim">{{this.appService.getDocName(proposedData[0]?.chapter4a80c?.nsc_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.nsc_document) }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.nsc_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.nsc_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.nsc_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.nabard_rural_bond">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">NABARD rural bonds</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.nabard_rural_bond_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.nabard_rural_bond_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.nabard_rural_bond_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.nabard_rural_bond_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.nabard_rural_bond_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.nabard_rural_bond_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.lic">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Life insurance premium</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.lic_amount| currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span
                                      class="tips text-uppercase fs- fw-700 text-trim">{{this.appService.getDocName(proposedData[0]?.chapter4a80c?.lic_document)
                                      }}</span>
                                    <span
                                      class="tips-helper ">{{this.appService.getDocName(proposedData[0]?.chapter4a80c?.lic_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.lic_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.lic_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.lic_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.annuity">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Annuity plan of LIC or other insurer towards pension scheme (80 CCC)
                              </p><span class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.annuity_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.annuity_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.annuity_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.annuity_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.annuity_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.annuity_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.deduction_contribute_NPS">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Deduction in respect of contribution to NPS (80 CCD(1))</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.nps_amount| currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span
                                      class="tips text-uppercase fs- fw-700 text-trim">{{this.appService.getDocName(proposedData[0]?.chapter4a80c?.nps_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.nps_document) }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.nps_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.nps_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.nps_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.equity_linked_saving">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Equity linked saving schemes (tax saving mutual funds) ELSS</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.ELSS_amount| currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.ELSS_document) }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.ELSS_document) }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.ELSS_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.ELSS_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.ELSS_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.ULIP">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">ULIP</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.ULIP_amount| currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.ULIP_document) }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.ULIP_document) }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.ULIP_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.ULIP_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.ULIP_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.interest_NSC">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Interest on national saving certificate</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.interest_NSC_amount| currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.interest_nsc_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.interest_nsc_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.interest_nsc_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.interest_nsc_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.interest_nsc_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.child_education_tuition_fees">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Children education tuition fees</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.tuition_fees| currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span
                                      class="tips text-uppercase fs- fw-700 text-trim">{{this.appService.getDocName(proposedData[0]?.chapter4a80c?.tuition_fees_document)
                                      }}</span>
                                    <span
                                      class="tips-helper ">{{this.appService.getDocName(proposedData[0]?.chapter4a80c?.tuition_fees_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.tuition_fees_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.tuition_fees_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.tuition_fees_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.repay_house_principal">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Repayment of housing principal</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.repay_house_amount| currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.repay_house_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.repay_house_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.repay_house_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.repay_house_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.repay_house_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.stampduty_purchase_house">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Stamp duty paid for purchase of house property</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.stampduty_amount| currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.stampduty_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.stampduty_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.stampduty_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.stampduty_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.stampduty_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4a80c?.saukanya_samridhi_yojana">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Sukanya samriddhi yojana</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4a80c?.ssy_amount| currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.ssy_document) }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4a80c?.ssy_document) }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4a80c?.ssy_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4a80c?.ssy_document,this.appService.getDocName(proposedData[0]?.chapter4a80c?.ssy_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- New end -->

                      <div class="col-12 ">
                        <div class="box-1 py-16 fw-500 ">Total exemptions under chapter VI A - 80C<div class="ms-auto ">
                            {{!proposedData[0]?.tax_regime?(taxRegimeData?.chapter4a80c?.chapter4a80c_data| currency:
                            currency):(0| currency: currency)}}</div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="4">
                  <a ngbNavLink class="tab-link ">
                    <span class="text-trim">Chapter VI A - Deductions</span>
                  </a>
                  <ng-template ngbNavContent class="h-100">
                    <div class="row row-12 ">
                      <div class="col-12">
                        <div class="card details-card indicater1 px-16 py-12">
                          <div class="row">
                            <div class="col-5">
                              <div class="fw-600 fs-14 text-uppercase">Chapter VI A - Deductions</div>
                            </div>
                            <div class="col">
                              <div class="detail-title">Total amount declared</div>
                              <div class="detail-desc"> {{taxRegimeData?.declared_amount?.chapter4adeduction| currency:
                                currency}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mt-16 d-flex align-items-end">
                        <div class=" fw-500">Chapter VI A - Deductions</div>
                      </div>


                      <!-- New start -->

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.health_insurance_selfspouse80d">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80D : Health insurance premium for self / spouse or dependent
                                children</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.health_insurance_selfspouse80d_amount
                                | currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_insurance_selfspouse80d_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_insurance_selfspouse80d_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.health_insurance_selfspouse80d_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.health_insurance_selfspouse80d_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_insurance_selfspouse80d_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.health_insurance_parents80d">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80D : Health insurance premium for dependent parents</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.health_insurance_parents80d_amount
                                | currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_insurance_parents80d_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_insurance_parents80d_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.health_insurance_parents80d_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.health_insurance_parents80d_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_insurance_parents80d_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.health_checkup_selfspouse80d">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80D : Preventive health checkup for self / spouse or dependent
                                children</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.health_checkup_selfspouse80d_amount
                                | currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_checkup_selfspouse80d_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_checkup_selfspouse80d_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.health_checkup_selfspouse80d_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.health_checkup_selfspouse80d_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_checkup_selfspouse80d_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.health_checkup_parents80d">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80D : Preventive health checkup for dependent parents</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.health_checkup_parents80d_amount
                                | currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_checkup_parents80d_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_checkup_parents80d_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.health_checkup_parents80d_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.health_checkup_parents80d_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.health_checkup_parents80d_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.medicalbill_senior80d">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Medical bills for super senior citizen</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.medicalbill_senior80d_amount
                                | currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.medicalbill_senior80d_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.medicalbill_senior80d_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.medicalbill_senior80d_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.medicalbill_senior80d_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.medicalbill_senior80d_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.employee_additional_contribution">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80CCD1(B): Employee’s additional contribution to NPS</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.employee_contribution_amount
                                | currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.employee_contribution_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.employee_contribution_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.employee_contribution_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.employee_contribution_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.employee_contribution_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.employer_contribution">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80CCD(2):Employer’s contribution to NPS account, deducted from
                                salary</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.employer_contribution_amount
                                | currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.employer_contribution_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.employer_contribution_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.employer_contribution_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.employer_contribution_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.employer_contribution_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.tta_ttb">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80TTA: Exemption of interest from banks, post office, etc. (or)
                                80TTB: Exemption of interest from banks, post office, etc.</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.tta_ttb_amount | currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.tta_tta_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.tta_tta_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.tta_tta_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.tta_tta_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.tta_tta_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.interest_education_loan">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80E: Interest on education loan</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.interest_education_amount
                                | currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_education_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_education_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.interest_education_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.interest_education_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_education_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.interest_home_loan_80ee">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80EE: Additional Interest on home loan for first-time homeowners
                                borrowed between 1st april 2016 to 31st march 2017</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.interest_home_loan_amount80ee
                                | currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_home_loan_document80ee)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_home_loan_document80ee)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.interest_home_loan_document80ee)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.interest_home_loan_document80ee,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_home_loan_document80ee))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.interest_home_loan_80eea">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80EEA: Additional interest on home loan for first-time homeowners
                                borrowed between 1st april 2019 to 31st march 2022</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.interest_home_loan_amount80eea
                                | currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_home_loan_document80eea)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_home_loan_document80eea)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.interest_home_loan_document80eea)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.interest_home_loan_document80eea,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_home_loan_document80eea))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12"
                        *ngIf="proposedData[0]?.chapter4adeduction?.interest_electrical_vehicle_loan_80eeb">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80EEB: Interest on electrical vehicle loan borrowed between 1st
                                april 2019 to 31st march 2023</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.interest_electrical_vehicle_loan_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_electrical_vehicle_loan_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_electrical_vehicle_loan_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.interest_electrical_vehicle_loan_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.interest_electrical_vehicle_loan_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.interest_electrical_vehicle_loan_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.medical_treatment_handicapped">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80 DD: Medical treatment for handicapped dependent</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.handicapped_dependent_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.handicapped_dependent_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.handicapped_dependent_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.handicapped_dependent_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.handicapped_dependent_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.handicapped_dependent_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.medical_expenditure">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80DDB: Medical expenditure as per rule 11DD</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.medical_expenditure_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.medical_expenditure_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.medical_expenditure_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.medical_expenditure_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.medical_expenditure_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.medical_expenditure_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.self_suffering_disability">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80U: Self-suffering from disability</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.self_suffering_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.self_suffering_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.self_suffering_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.self_suffering_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.self_suffering_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.self_suffering_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.donate_social_cause100">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80G: Deduction for donations towards social causes (100% exemption)
                              </p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.donate_social_cause100_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.transaction_document100)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.transaction_document100)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.transaction_document100)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.transaction_document100,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.transaction_document100))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.donate_social_cause_50">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80G: Deduction for donations towards social causes (50% exemption)
                              </p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.donate_social_cause50_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.transaction_document50)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.transaction_document50)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.transaction_document50)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.transaction_document50,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.transaction_document50))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.royalty_patent">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80RRB: Deductions on income by way of royalty of a patent</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.royalty_patent_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.royalty_patent_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.royalty_patent_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.royalty_patent_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.royalty_patent_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.royalty_patent_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12"
                        *ngIf="proposedData[0]?.chapter4adeduction?.contribution_individual_policalparties">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80GGC: Contribution by individuals to political parties</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.contribution_policalparties_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.contribution_policalparties_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.contribution_policalparties_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.contribution_policalparties_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.contribution_policalparties_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.contribution_policalparties_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.chapter4adeduction?.gga80">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80GGA: Deduction in respect of donations to certain funds,
                                charitable institutions, scientific research, and rural development etc.</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.chapter4adeduction?.total_amount| currency:
                                currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.transaction_80gga_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.chapter4adeduction?.transaction_80gga_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.chapter4adeduction?.transaction_80gga_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.chapter4adeduction?.transaction_80gga_document,this.appService.getDocName(proposedData[0]?.chapter4adeduction?.transaction_80gga_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- New end -->

                      <div class="col-12 ">
                        <div class="box-1 py-16 fw-500 ">Total exemptions under chapter VI A - deductions<div
                            class="ms-auto ">{{!proposedData[0]?.tax_regime?
                            (taxRegimeData?.chapter4adeduction?.chapter4adeduction_data| currency: currency):(0|
                            currency: currency)}}</div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="5">
                  <a ngbNavLink class="tab-link ">
                    <span class="text-trim">Income/Loss from property</span>
                  </a>
                  <ng-template ngbNavContent class="h-100">
                    <div class="row row-12 ">
                      <div class="col-12">
                        <div class="card details-card indicater1 px-16 py-12">
                          <div class="row">
                            <div class="col-5">
                              <div class="fw-600 fs-14 text-uppercase">income/loss from property</div>
                            </div>
                            <div class="col">
                              <div class="detail-title">Maximum limit</div>
                              <div class="detail-desc">{{proposedData[0]?.income_loss_property | currency: currency}}
                              </div>
                            </div>
                            <div class="col">
                              <div class="detail-title">Total amount declared</div>
                              <div class="detail-desc"> {{taxRegimeData?.declared_amount?.incomeloss_property| currency:
                                currency}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mt-16 d-flex align-items-end">
                        <div class=" fw-500">Income/Loss from Property</div>
                      </div>

                      <!-- New start -->
                      <div class="col-12"
                        *ngIf="proposedData[0]?.incomeloss_property?.incomefrom_self_occupiedproperty">


                        <div class="col-12"
                          *ngIf="proposedData[0]?.incomeloss_property?.incomefrom_self_occupiedproperty">
                          <div class="card card-c2  px-24 py-16 ">
                            <div class="row row-12">
                              <div class="col-12 d-flex">
                                <p class="mb-0 me-24">Income from self occupied property</p><span
                                  class="ms-auto fw-500">{{proposedData[0]?.incomeloss_property?.intreston_houseloan_amount|
                                  currency: currency}}</span>
                              </div>
                              <div style="width:14.438rem;">
                                <div class="card card-c2  bg-secondary">
                                  <div class="p-12 bg-secondary d-flex align-item-center">

                                    <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                    <div
                                      class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                      <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                        this.appService.getDocName(proposedData[0]?.incomeloss_property?.intreston_houseloan_document)
                                        }}</span>
                                      <span class="tips-helper ">{{
                                        this.appService.getDocName(proposedData[0]?.incomeloss_property?.intreston_houseloan_document)
                                        }}</span>

                                      <div class="d-flex gap-8">
                                        <div class="link-primary"
                                          (click)="viewDocument(proposedData[0]?.incomeloss_property?.intreston_houseloan_document)">
                                          <i class="bi bi-eye me-1"></i>View
                                        </div>
                                        <div class="link-primary"
                                          (click)="this.appService.downloadFile(proposedData[0]?.incomeloss_property?.intreston_houseloan_document,this.appService.getDocName(proposedData[0]?.incomeloss_property?.intreston_houseloan_document))">
                                          <i class="bi bi-download me-1"></i>Download
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- New end -->

                      <div class="col-12" *ngIf="proposedData[0]?.incomeloss_property?.loss_lender?.length > 0">
                        <ngb-accordion class="custom-accordion1 border-0" #acc="ngbAccordion" activeIds="ngb-panel-0">
                          <ngb-panel id="ngb-panel-1">
                            <ng-template ngbPanelHeader>
                              <button ngbPanelToggle class="accordion-button">
                                Income from let-out property
                                <span class="ms-auto">{{netIncome| currency: currency}}</span>
                              </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <div class="d-flex flex-column gap-12 fs-14 text-light-500">


                                <!-- New start -->
                                <ng-container
                                  *ngFor="let lossLender of proposedData[0]?.incomeloss_property?.loss_lender,let i = index">
                                  <div class="d-flex w-100">Property {{(i+1)}}<div class="ms-auto ">
                                      {{lossLender?.net_income_houseproperty| currency: currency}}</div>
                                  </div>
                                  <div style="width:14.438rem;">
                                    <div class="card card-c2  bg-secondary">
                                      <div class="p-12 bg-secondary d-flex align-item-center">

                                        <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                        <div
                                          class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                          <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                            this.appService.getDocName(lossLender?.net_income_houseproperty_document)
                                            }}</span>
                                          <span class="tips-helper ">{{
                                            this.appService.getDocName(lossLender?.net_income_houseproperty_document)
                                            }}</span>

                                          <div class="d-flex gap-8">
                                            <div class="link-primary"
                                              (click)="viewDocument(lossLender?.net_income_houseproperty_document)">
                                              <i class="bi bi-eye me-1"></i>View
                                            </div>
                                            <div class="link-primary"
                                              (click)="this.appService.downloadFile(lossLender?.net_income_houseproperty_document,this.appService.getDocName(lossLender?.net_income_houseproperty_document))">
                                              <i class="bi bi-download me-1"></i>Download
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                </ng-container>
                                <!-- New end -->

                              </div>
                            </ng-template>
                          </ngb-panel>
                        </ngb-accordion>
                      </div>
                      <!-- </ng-container> -->
                      <div class="col-12 ">
                        <div class="box-1 py-16 fw-500 ">Total exemptions under income/loss from property<div
                            class="ms-auto ">
                            {{!proposedData[0]?.tax_regime?(taxRegimeData?.incomeloss_property?.total_income_loss_property
                            | currency: currency):(0 | currency: currency)}}</div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="6">
                  <a ngbNavLink class="tab-link ">
                    <span class="text-trim">Income from other sources</span>
                  </a>
                  <ng-template ngbNavContent class="h-100">
                    <div class="row row-12 ">
                      <div class="col-12">
                        <div class="card details-card indicater1 px-16 py-12">
                          <div class="row">
                            <div class="col-5">
                              <div class="fw-600 fs-14 text-uppercase">income from OTHER SOURCES</div>
                            </div>
                            <div class="col">
                              <div class="detail-title">Total amount declared</div>
                              <div class="detail-desc"> {{taxRegimeData?.declared_amount?.income_from_othersource |
                                currency: currency}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mt-16 d-flex align-items-end">
                        <div class=" fw-500">Income from other sources</div>
                      </div>



                      <!-- New start -->

                      <div class="col-12" *ngIf="proposedData[0]?.income_from_othersource?.incomefrom_othersource">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Income from other sources</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.income_from_othersource?.othersource_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.income_from_othersource?.othersource_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.income_from_othersource?.othersource_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.income_from_othersource?.othersource_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.income_from_othersource?.othersource_document,this.appService.getDocName(proposedData[0]?.income_from_othersource?.othersource_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.income_from_othersource?.interest_earned_sbaccount">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Interest earned through saving bank account</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.income_from_othersource?.sb_account_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.income_from_othersource?.sb_account_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.income_from_othersource?.sb_account_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.income_from_othersource?.sb_account_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.income_from_othersource?.sb_account_document,this.appService.getDocName(proposedData[0]?.income_from_othersource?.sb_account_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.income_from_othersource?.source_80TTA_80TTB">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">80TTA/ 80TTB</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.income_from_othersource?.source_80TTA_80TTB_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.income_from_othersource?.source_80TTA_80TTB_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.income_from_othersource?.source_80TTA_80TTB_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.income_from_othersource?.source_80TTA_80TTB_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.income_from_othersource?.source_80TTA_80TTB_document,this.appService.getDocName(proposedData[0]?.income_from_othersource?.source_80TTA_80TTB_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12" *ngIf="proposedData[0]?.income_from_othersource?.intrest_earned">
                        <div class="card card-c2  px-24 py-16 ">
                          <div class="row row-12">
                            <div class="col-12 d-flex">
                              <p class="mb-0 me-24">Interest earned from national savings certificate</p><span
                                class="ms-auto fw-500">{{proposedData[0]?.income_from_othersource?.intrest_earned_amount|
                                currency: currency}}</span>
                            </div>
                            <div style="width:14.438rem;">
                              <div class="card card-c2  bg-secondary">
                                <div class="p-12 bg-secondary d-flex align-item-center">

                                  <i class="icon-file-earmark-text-fill fs-40 me-8 text-accent2"></i>
                                  <div
                                    class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                                    <span class="tips text-uppercase fs- fw-700 text-trim">{{
                                      this.appService.getDocName(proposedData[0]?.income_from_othersource?.intrest_earned_document)
                                      }}</span>
                                    <span class="tips-helper ">{{
                                      this.appService.getDocName(proposedData[0]?.income_from_othersource?.intrest_earned_document)
                                      }}</span>

                                    <div class="d-flex gap-8">
                                      <div class="link-primary"
                                        (click)="viewDocument(proposedData[0]?.income_from_othersource?.intrest_earned_document)">
                                        <i class="bi bi-eye me-1"></i>View
                                      </div>
                                      <div class="link-primary"
                                        (click)="this.appService.downloadFile(proposedData[0]?.income_from_othersource?.intrest_earned_document,this.appService.getDocName(proposedData[0]?.income_from_othersource?.intrest_earned_document))">
                                        <i class="bi bi-download me-1"></i>Download
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- New end -->

                      <div class="col-12 ">
                        <div class="box-1 py-16 fw-500 ">Total exemptions under income from other sources<div
                            class="ms-auto ">
                            {{taxRegimeData?.earnings?.income_from_othersource?.income_from_othersource| currency:
                            currency}}</div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-8 col-sm-7 col-12">
          <div [ngbNavOutlet]="nav" class="h-100"></div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>

<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='detailedTaxCalculation === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Detailed Tax Calculation</h5>
      <a class="toggle-panel" (click)="detailedTaxCalculation = false"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid px-32 py-24 ">
        <div class="row row-12 ">
          <div class="col-12">
            <div class="card details-card p-16">
              <div class="row">
                <div class="col-6 d-flex align-items-start">
                  <div class="fw-600 fs-14 title text-uppercase">{{proposedData[0]?.tax_regime?'NEW TAX REGIME':'OLD TAX REGIME'}}</div>
                  <span class="badge badge-success ms-8 px-12 fs-10"> ACTIVE DECLARATION</span>
                </div>
                <div class="col">
                  <div class="detail-title">Total taxable income</div>
                  <div class="detail-desc">{{taxRegimeData?.regime_summary?.total_tax_income| currency: currency}}</div>
                </div>
                <div class="col">
                  <div class="detail-title">Total tax</div>
                  <div class="detail-desc"> {{taxRegimeData?.regime_summary?.total_tax| currency: currency}}</div>
                </div>
              </div>
            </div>
          </div>

          <app-view-regime-comparison class="col-12" [oldNewFlag]="proposedData[0]?.tax_regime"
            [taxRegimeData]="taxRegimeData" [hra80gg]="hra80ggData"></app-view-regime-comparison>


        </div>
      </div>
    </div>

  </div>
</div>

<!-- un supported view format alert -->
<ng-container *ngIf="alertToggle==true">
  <app-alert-toggle (modals)="closed($event)" [alertToggle]="alertToggle"
    [alertMsg]="investalertMsg"></app-alert-toggle>
</ng-container>

<!-- Viewing the Document -->
<ng-container *ngIf="modalToggle== true">
  <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName"
    [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
</ng-container>
