import { Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import { AppService } from 'src/app/app.global';
// import * as XLSX from 'xlsx';
@Component({
  selector: 'app-error-download',
  templateUrl: './error-download.component.html',
  styleUrls: ['./error-download.component.scss']
})
export class ErrorDownloadComponent implements OnInit {
  @Input() downloadMsg = false;
  @Input() successDataCount = 0;
  @Input() failedData:any = [];
  @Input() fileName = '';
  @Input() modalHeading = '';
  @Input() successMsg = '';
  @Input() errorMsg = '';

  @Output() closePanel = new EventEmitter;
  constructor(public appService: AppService) { }

  ngOnInit(): void {  
  }

  downloadError(){
    console.log("this.failedData",this.failedData)
    console.log("this.fileName",this.fileName)
    this.appService.exportExcel( this.failedData,this.fileName);
    this.downloadMsg = false;
    this.closePanel.emit();
  }

}
