import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-view-investment-declaration',
  templateUrl: './view-investment-declaration.component.html',
  styleUrls: ['./view-investment-declaration.component.scss']
})
export class ViewInvestmentDeclarationComponent implements OnInit {
  from:any='HR';
  activeToggle:any='';
  year:any='';
  emp_id:any=0;

  constructor(
    public route:ActivatedRoute,
    public router:Router,
    private _location: Location,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if( !isNaN(params['year'])){
        this.year = params['year'];
      }
      if( isNaN(params['activeToggle'])){
        this.activeToggle = params['activeToggle'];
      }
      if( !isNaN(params['emp_id'])){
        this.emp_id = parseInt(params['emp_id']);
      }
     
    })
  }
  backClicked() {
      this._location.back();
  }
}
