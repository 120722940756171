import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { HalfFullDayValidator } from 'src/app/@shared/validators/halfFullDay.validators';
import { MessageService } from 'src/app/message.global';
@Component({
  selector: 'app-ar-late-coming-penalty',
  templateUrl: './ar-late-coming-penalty.component.html',
  styleUrls: ['./ar-late-coming-penalty.component.scss']
})
export class ArLateComingPenaltyComponent implements OnInit {

  constructor(public messageService : MessageService, public leaveType : leavetypeService, public fb : FormBuilder) { }
  @Input() formGp:any  = []
  @Input() detailsForm:any  = []

  submitted = false;
  @Input() leaveTypes   : any = []
  lateData      : any = ['grace_days_cond','incident_penalize','grace_days_count','exceeded_limit','exceeded_deduct_days']
  lateNoData    : any = ['grace_days_cond','incident_penalize','grace_days_count','exceeded_limit','exceeded_deduct_days']
  deducData     : any = ['incident_cumulative','cumulative_type']
  deducNoData     : any = ['incident_cumulative','cumulative_type']
  deductionTypes : any = [
    {id :'Unauthorized absence',value :'Unauthorized absence'},
    {id :'Half day minimum hours',value :'Half day minimum hours'},
    {id :'Full day minimum hours',value :'Full day minimum hours'},
  ]
  penalisation_dropdown = ['Day','Month','Week']
  @Input()disabled = false;
  @Output() submitmethod = new EventEmitter();

  ngOnInit(): void {
    this.selectAllForDropdownItems(this.deductionTypes)
  }

  ngAfterViewInit() {
    // if(this.leave_prio().length > 0){
    //   this.updateDuplicateValidation()
    // }
  }

  // New Changes
  onCheckboxChange(event: any,control:any) {
    const incidentPenalizeArray = this.formGp.get(control) as FormArray;
    if (event.target.checked) {
      incidentPenalizeArray.push(this.fb.control(event.target.value));
    } else {
      const index = incidentPenalizeArray.controls
        .findIndex(x => x.value === event.target.value);
      if (index !== -1) {
        incidentPenalizeArray.removeAt(index);
      }
    }
  }

  maxGraceLimit : any = 0

  clear(index:any,formCntrl:any){
    if(formCntrl == 'ignore_penalize_option'){
      let data = this.formGp.value.ignore_penalize_option;
      data.splice(index, 1);
      this.formGp.patchValue({
        ignore_penalize_option: data
      });
    }
  }

  // New Changes End
  get f() {return  this.formGp.controls; }

  validateForm() {
    this.submitted = true;
    if (this.formGp.invalid) {
      return;
    }
    this.submitmethod.emit(3);
  }


  leave_prio() : FormArray{
    return this.formGp.get('prior_deduct_attendancerule') as FormArray
  }

  createLeaveFormGroup(): FormGroup {
    return this.fb.group({
      'leave_type': new FormControl(null,[Validators.required]),
      'priority': new FormControl(this.leave_prio().length+1)
    })
  }

  addLeaves(){
    this.leave_prio().push(this.createLeaveFormGroup())
  }

  deduction_prio() : FormArray{
    return this.formGp.get('cumulative_criteria') as FormArray
  }

  createDeductionFormGroup(): FormGroup {
    return this.fb.group({
      'cumulative_hours': new FormControl(null,[Validators.required,Validators.min(1),Validators.max(24)]),
      'count_days': new FormControl(null,[Validators.required,HalfFullDayValidator(),Validators.max(365)])
    })
  }

  addDeduction(){
    this.deduction_prio().push(this.createDeductionFormGroup())
  }

  setRequired(value: boolean,data:any) {
    if(value) {
    data.forEach((item:any)=>{
      this.formGp.get(item).setValidators([Validators.required])
      if(item == 'exceeded_limit'){
        this.formGp.get(item).setValidators([Validators.required,Validators.min(1),Validators.max(365)])
      }else if(item == 'exceeded_deduct_days'){
        this.formGp.get(item).setValidators([Validators.required,Validators.max(365),HalfFullDayValidator()])
      }else if(item == 'grace_days_count'){
        this.maxGraceLimit = this.formGp.get('grace_days_cond')?.value ? 28 : 7;
        this.formGp.get('grace_days_count').setValidators([
          Validators.required,
          Validators.min(1),
          Validators.max(this.maxGraceLimit),
          Validators.pattern('^[0-9]+$')
        ]);
      }
    })
    } else {
      data.forEach((item:any)=>{
        this.formGp.get(item).clearValidators()
      })
    }
    data.forEach((item:any)=>{
      if(item == 'grace_days_cond'){
        this.formGp.get(item)?.setValue(false);
      }else if(item == 'incident_penalize' || item == 'incident_cumulative'){
        let control = this.formGp.get(item) as FormArray
        control.clear()
      }else{
        this.formGp.get(item)?.setValue(null);
      }
      this.formGp.get(item).updateValueAndValidity()
    })
  }

  isChecked(value: string,control:any): boolean {
    const incidentPenalizeArray = this.formGp.get(control) as FormArray;
    return incidentPenalizeArray.value.includes(value);
  }

  setValidationGrace(){
    let control = this.formGp.get('grace_days_count')
    control?.setValue(null)
    this.maxGraceLimit = this.formGp.get('grace_days_cond')?.value ? 28 : 7;
    control.setValidators([
      Validators.required,
      Validators.min(1),
      Validators.max(this.maxGraceLimit),
      Validators.pattern('^[0-9]+$')
    ]);
    control?.markAsTouched()
    control.updateValueAndValidity();
  }

  notanumber(val:any){
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }


  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  deletePriority(index:number){
    this.leave_prio().removeAt(index);
  }

  deleteDeduction(index:number){
    this.deduction_prio().removeAt(index);
  }

  clearDeduction(){
    this.deduction_prio().clear()
  }

  setDisabled(value:any){
    if(value && this.leave_prio().length<= 0){
      this.addLeaves()
    } else {
      while ( this.leave_prio().length !== 0) {
        this.leave_prio().removeAt(0)
      }
    }
  }

  leaveList:any //duplicate
  duplicateLeaveType(index:any){
    let val = this.leave_prio()?.controls[index]?.get('leave_type')?.value
    for(let i=0;i<this.leave_prio().length;i++){
      if(i!=index){
      let valx = this.leave_prio()?.controls[i]?.get('leave_type')?.value
      if(val == valx){
        this.leave_prio()?.controls[index]?.get('leave_type')?.setErrors({
          'duplicate' : true
        })
      }
      }
    }
  }

  updateDuplicateValidation(){
    for(let i=1;i<this.leave_prio().length;i++){
      this.duplicateLeaveType(i)
    }
  }

  onHoursKeyUp(index: number) {
  const currentControl = this.deduction_prio().at(index).get('cumulative_hours');
  const currentValue = currentControl?.value
  const isDuplicate = this.deduction_prio().controls.some((control, idx) =>
    idx !== index && parseInt(control.get('cumulative_hours')?.value) === parseInt(currentValue)
  );
    if (isDuplicate) {
      currentControl?.setErrors({ 'duplicate': true });
    } else {
      currentControl?.setErrors(null);
      currentControl?.updateValueAndValidity();
    }
  }
}
